import type { Location, User } from "~/server/db/schema";
import { UserRole } from "~/lib/models/user.model";

export function isTuple<T>(array: T[]): array is [T, ...T[]] {
  return array.length > 0;
}

export const getEnabledLabel = (value: boolean) => {
  return value ? "Yes" : "No";
};

export const isConvertibleToDate = (value: any) => {
  return !isNaN(Date.parse(value));
};

export const formatDate = (value: Date) => {
  if (!isConvertibleToDate(value)) {
    return "Unknown";
  } else {
    const date = new Date(value);
    return date.toLocaleString("en-US");
  }
};

export const formatCoordinate = (value: string | undefined | null) => {
  if (!value) {
    return "Not set";
  }
  const coord = parseFloat(value);
  if (isNaN(coord)) {
    return "Not set";
  }
  return coord.toFixed(4);
};

export const isNumber = (value: any) => {
  return typeof value === "number" && !Number.isNaN(value);
};

export const makeAddress = (
  street?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
) => {
  // If all fields are empty/null, return "Not set"
  if (!street && !city && !state && !zip) {
    return "Not set";
  }

  // Build address with available parts
  const parts = [];
  if (street) parts.push(street);
  if (city) parts.push(city);
  if (state) parts.push(state);
  if (zip) parts.push(zip);

  return parts.join(", ");
};

export const makeUserAddress = (user: User) => {
  return makeAddress(
    user.companyStreet!,
    user.companyCity!,
    user.companyState!,
    user.companyZip!,
  );
};

export const makeLocationAddress = (location: Location) => {
  return makeAddress(
    location.street,
    location.city,
    location.state,
    location.zip,
  );
};

export const formatCurrency = (value: string | number) => {
  return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

export const formatNumber = (value: string | number) => {
  return value.toLocaleString("en-US");
};

export const navigateToDashboard = (role: UserRole): string => {
  // Add validation for role
  if (!Object.values(UserRole).includes(role)) {
    console.error(`Invalid role value: ${role}`);
    return "/";
  }

  const dashboardPaths: Record<UserRole, string> = {
    [UserRole.UNKNOWN]: "/",
    [UserRole.SUPER_ADMIN]: "/admin",
    [UserRole.LOCATION_OWNER]: "/locationowner",
    [UserRole.OPERATOR]: "/operator",
    [UserRole.AD_AGENCY]: "/adagency",
    [UserRole.INSTALLER]: "/installer",
  };

  const path = dashboardPaths[role];

  if (!path) {
    console.error(`No dashboard path found for role: ${role}`);
    return "/";
  }

  return path;
};
