import revive_payload_client_h3F5Mk7TOB from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_better-sqlite3@11.5.0_drizzle-orm@0.36_semycmqfh5agl3hlrwzm4x45e4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JHyb65GBSV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_better-sqlite3@11.5.0_drizzle-orm@0.36_semycmqfh5agl3hlrwzm4x45e4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ACPDIdkN1H from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_better-sqlite3@11.5.0_drizzle-orm@0.36_semycmqfh5agl3hlrwzm4x45e4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_9S4rEekokL from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_better-sqlite3@11.5.0_drizzle-orm@0.36_semycmqfh5agl3hlrwzm4x45e4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6Im094NVQf from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_better-sqlite3@11.5.0_drizzle-orm@0.36_semycmqfh5agl3hlrwzm4x45e4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_tQ8jDeScHH from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_better-sqlite3@11.5.0_drizzle-orm@0.36_semycmqfh5agl3hlrwzm4x45e4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_3lPkimQQaF from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_better-sqlite3@11.5.0_drizzle-orm@0.36_semycmqfh5agl3hlrwzm4x45e4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_WYE3RszQzi from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_better-sqlite3@11.5.0_drizzle-orm@0.36_semycmqfh5agl3hlrwzm4x45e4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_RG0GFG5Zk4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-echarts@0.2.4_echarts@5.5.1_magicast@0.3.5_rollup@4.25.0_webpack-sources@3.2.3/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/opt/buildhome/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_eS9zVVKmS0 from "/opt/buildhome/repo/node_modules/.pnpm/@primevue+nuxt-module@4.2.2_@babel+parser@7.26.2_magicast@0.3.5_rollup@4.25.0_vue@3.5.12_type_3poziarzivamcymfp7msx6law4/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import _01_sentryClientPlugin_Riwl5WCia6 from "/opt/buildhome/repo/plugins/01.sentryClientPlugin.ts";
import _02_auth_kllCetJ9Sb from "/opt/buildhome/repo/plugins/02.auth.ts";
export default [
  revive_payload_client_h3F5Mk7TOB,
  unhead_JHyb65GBSV,
  router_ACPDIdkN1H,
  payload_client_9S4rEekokL,
  navigation_repaint_client_6Im094NVQf,
  check_outdated_build_client_tQ8jDeScHH,
  chunk_reload_client_3lPkimQQaF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_WYE3RszQzi,
  plugin_RG0GFG5Zk4,
  primevue_plugin_egKpok8Auk,
  plugin_client_eS9zVVKmS0,
  _01_sentryClientPlugin_Riwl5WCia6,
  _02_auth_kllCetJ9Sb
]